 
    .home__hero-section4 {
    color: rgb(255, 255, 255);
    padding: 80px 0px 350px 0px  ;
    
    }
    img {
        margin-top: 50px;
        border: 0;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
      }
      .dis{
        display:none;

      }
      .aper{
       margin-top:   35px;
 
      }
    @media screen and (max-width: 791px) {
       
        #img{
            display:none;
          }
    }
    
    @media screen and (max-width: 700px) {
       
        #img{
            display:none;
          }
          .aper{
            margin-top:   30px;
          
           }
    }
    