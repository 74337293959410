.home__hero-section3 {
  color: #fff;
  height: 120%;
  width: 100%;
  padding: 22px 0px 20px 0px;
}

.home__hero-row3 {
  margin-top: 23px;
}
.text {
  margin-top: 150px;
}
.c33 {
  background-color: white;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  width: 95%;
  background-color: #1c2237;
}
.colb {
  position: absolute;
  margin-top: -203px;
  left: 10%;

  height: 50px;
  transform: translate(-50%, 00%);
}
.home__hero-row {
  align-items: left;
}

.row {
  display: flex;
}

.col {
  text-align: center;

  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.text {
  margin-right: 13vw;
  font-size: 22px;
  text-align: right;
}
.home__hero-text-wrapper {
  text-align: center;
  max-width: 540px;
  padding-top: 0;

  padding-bottom: 0px;
}

.home__hero-subtitle2 {
  max-width: 440px;
  text-align: center;
  margin-bottom: 35px;

  font-size: 22px;
  line-height: 24px;
}

@media screen and (max-width: 799px) {
  .container3 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .colb {
    background-color: transparent;
    position: absolute;
    margin-top: -25px;
    left: 50%;
    width: 30%;
    transform: translate(-50%, 00%);
  }
  .home__hero-section3 {
    padding: 20px 0px;
  }
}

@media screen and (max-width: 700px) {
  .colb {
    position: absolute;
    margin-top: -55px;
    left: 50%;
    background-color: transparent;
    width: 30%;
    transform: translate(-50%, 00%);
  }
  .home__hero-section3 {
    padding: 30px 0px 0px 0px;
  }
  .c33 {
    width: 100%;
  }
  .col {
    max-width: 100%;
    flex-basis: 100%;
  }
}
