.form-container {
    
    width: 100%;
  min-height: 600px;
    position: relative;
    border-radius: 10px;
    height: 500px;
    animation: mymove 0.1s ease-out;
    grid-template-columns: 1fr 1fr;
  }
  .done {
    
    width: 100%;
    background-color: transparent;
   
    position: relative;
    border-radius: 10px;
    height: 500px;
    animation: mymove 0.1s ease-out;
    grid-template-columns: 1fr 1fr;
  }
  @keyframes mymove {
    from {background-color: rgba(255, 255, 255, 0.199);}
    to {background-color: rgba(255, 255, 255, 0);}
  }
  .form-success {
    text-align: center;
    font-size: 24px;
    margin-top: 80px;
    color: #fff;
  }
  
  .form-content-right {
    border-radius: 0 10px 10px 0;
    margin-top: 20px;
     
    background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
  }
  
  .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
 
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form h1 {
    font-size: 0.8rem;
    text-align: right;
    width: 100%;
    margin-bottom: 2rem;
    color: #fff;
    
  }
  
  .form-inputs         {
   
    width: 100%;
    margin-right:2em;
 
  }
  #dis{
  display:none;
  }
  .form-inputs p {
    font-size: 0.9rem;
    margin-top: 0.4rem;
    color: #da2121;
  }
  
  .form-label {
    display: inline-block;
    font-size: 0.8rem;
    margin-bottom: 6px;
    color: #fff;
  }
  .se option{
background-color: rgb(255, 255, 255);
  }
  .form-input-red{
    display: block;
    background-color: rgb(255, 255, 255);
    text-align:center;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
    
    box-shadow: 0px 0px 17px 0px rgba(255,1,1,1) inset;
    -webkit-box-shadow: 0px 0px 17px 0px rgba(255,1,1,1) inset;
    -moz-box-shadow: 0px 0px 17px 0px rgba(255,1,1,1) inset;
animation: color-change 0.5s ease  ;
}
@keyframes color-change {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  .form-input {
    border-radius: 5px;
    display: block;
    text-align:center;
    outline: none;
     height: 40px;
    width: 100%;
    border: none;
    background-color: rgb(255, 255, 255);
    
  }
   
  .form-input::placeholder {
    color: #7a7a7a;
    font-size: 12px;
     
  }
  .form-input-btn {
    width:  50%;
    height: 50px;
    
    border-radius: 5px;
    background:  #276afb  ;
    outline: none;
    border: none;
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
  }
  
  .btns{
    align-items: center;
    justify-content: flex-end;
     display: flex;
     flex-direction: row;
  }
  select option:checked,
select option:hover {
    box-shadow: 0 0 10px 100px #000 inset;
}
  .form-input-btn:hover {
    cursor: pointer;
    background: #f00946;
    transition: all 0.1s ease-out;
  }
  
  .form-input-login {
    font-size: 0.8rem;
    margin-top: 10px;
    color: #fff;
    width: 80%;
    text-align: center;
  }
  
  .form-input-login a {
    text-decoration: none;
    color: #27cdff;
    font-weight: 600;
  }
  @media screen and (max-width: 800px) {
    .form-container {
     
  
 min-height: 500px;
    }
    
    .btns{
      display: "flex";
      margin-top: 20px;
      flex-direction: row;
    }
    .done {
    
      
    min-height: 1050px;
      
    }
}
  @media screen and (max-width: 600px) {
    .form-container {
     
      
      min-height:900px;
         }
        
         .done {
         
           
         min-height: 1050px;
           
         }
   
    
}  @media screen and (max-width: 300px) {

  .form-container {
     
    
    min-height: 900px;
       }
       .done {
       
         
       min-height: 1050px;
         
       }
 
}