.home__hero-section2 {
  color: #fff;
  padding: 0px 0px 0px 0px;
}

.nwsin {
  font-size: 1.3em;
}

.list {
  font-size: 1.2rem;
  text-align: center;
  list-style: none;

}

.listh {
  font-size: 1.4rem;



}

.top-lineR {

  text-align: right;
  margin-right: -10px;
  color: #CD5C5C;
  font-size: 30px;
  line-height: 16px;
  font-weight: 700;

  text-transform: uppercase;
  margin-bottom: 16px;
}

.home__hero-subtitleR {

  text-align: center;


  font-size: 1.3rem;
  line-height: 40px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
  overflow: hidden;
}

.home__hero-imgREB {
  max-width: 90%;
  margin-top: -150px;
  margin-right: 0;
  margin-left: 10px;
  padding-right: 0;
}

.home__hero-img-wrapperR {
  margin-top: 160px;
  max-width: 555px;
}

.home__hero-text-wrapperTB {
  text-align: center;
  max-width: 540px;
  margin-top: 20px;
  padding-top: 0;
  padding-bottom: 0px;
}

.col2 {
  text-align: center;
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 100%;
  flex-basis: 50%;
}

.T {
  margin-top: 10px;

}

.home__hero-sectionTB {
  color: #fff;
  height: 100%;
  width: 100%;


}

.home__hero-t {

  border-radius: 0.50rem;
  overflow: hidden;
  max-width: 450px;
  text-align: right;
  margin-bottom: 2vh;
  list-style-type: none;
  font-size: 22px;
  line-height: 24px;

  height: 400px;

}

.home__hero-tTB {
  border-radius: 0.50rem;
  overflow: hidden;
  max-width: 460px;
  text-align: right;

  list-style-type: none;
  font-size: 22px;
  line-height: 24px;

  height: 400px;

}

.dw {

  margin-top: 100px;
}

.dwT {

  display: none;
}

@media screen and (max-width: 900px) {
  .R {

    margin-top: -60px;
  }
}

.cool-link:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 700px) {
  #hd {
    display: none;

  }

  .home__hero-img-wrapperR {

    background-color: transparent;
  }



  .dw {
    display: none;
  }

  .dwT {
    margin-top: 40px;
    display: block;
  }

  .home__hero-t {

    height: 370px;

  }

  .home__hero-tTB {
    height: 170px;

  }

  .R {
    margin-top: 50px;

  }
}