* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  background-color: #1c2237;
}

.font-face-gm {
  font-family: "Rabar_022" !important;
}
