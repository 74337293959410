 .home__hero-section1 {
   color: #fff;
   height: 100%;
   width: 100%;
   ;


 }

 .home__hero-section4 {
   color: #fff;
   padding: 80px 0px 350px 0px;

 }

 #firstimage {
   margin-top: 10vh;
 }

 .container1 {

   width: 100%;


 }

 .home__hero-row {
   align-items: center;


 }

 .row1 {
   display: flex;
   margin-right: 15px;
   margin-bottom: 15px;
   margin-left: 15px;
   flex-wrap: wrap;
   flex-direction: row;
   align-content: stretch;
 }

 .row {
   display: flex;
   margin-right: 15px;
   margin-bottom: 15px;
   margin-left: 15px;
   flex-wrap: wrap;
   flex-direction: row;
   align-content: stretch;
 }

 .col {
   text-align: center;
   margin-bottom: 15px;
   padding-right: 15px;
   padding-left: 15px;
   flex: 1;
   max-width: 90%;
   flex-basis: 50%;
 }

 .col1 {
   text-align: center;
   margin-bottom: 15px;
   padding-right: 15px;
   padding-left: 15px;
   flex: 1;
   max-width: 90%;
   flex-basis: 50%;
 }

 .home__hero-text-wrapper {
   text-align: right;
   max-width: 540px;
   padding-top: 0px;
   padding-bottom: 60px;
 }

 .top-line1 {
   text-align: right;
   margin-right: -10px;
   color: #CD5C5C;
   font-size: 30px;
   line-height: 16px;
   font-weight: 700;

   text-transform: uppercase;
   margin-bottom: 16px;
 }

 .top-line {
   text-align: right;

   color: #CD5C5C;
   font-size: 30px;
   line-height: 16px;
   font-weight: 800;

   text-transform: uppercase;
   margin-bottom: 40px;
 }

 .bnj {
   display: none;
 }

 .heading {
   margin-bottom: 2vh;
   font-size: 35px;

   line-height: 1.1;
   font-weight: 600;
   color: #f7f8fa;
 }

 .dark {
   color: #1c2237;
 }



 .home__hero-subtitle {

   text-align: center;
   margin-bottom: 2vh;

   font-size: 1.3rem;
   line-height: 30px;
 }

 .home__hero-img-wrapper {
   max-width: 555px;

 }

 .home__hero-img {
   max-width: 95%;
   margin-top: 0;
   margin-right: 0;
   margin-left: 10px;
   padding-right: 0;
 }

 img {
   border: 0;
   max-width: 100%;
   vertical-align: middle;
   display: inline-block;
 }

 @media screen and (max-width: 791px) {
   .container {
     padding-right: 20px;
    }

   .home__hero-section1 {

     position: absolute;

   }
 }

 @media screen and (max-width: 700px) {
   .home__hero-text-wrapper {
     padding-bottom: 190px;
   }

   .home__hero-section1 {
     height: 70%;

     position: absolute;


   }

   .heading {
     margin-bottom: 3vh;
     margin-top: -2vh;
   }

   #firstimage {
     margin-top: 7vh;
   }

   .row1 {

     flex-direction: column-reverse;

   }

   .home__hero-subtitle {

     margin-bottom: -10vh;

     font-size: 1.5rem;
   }


   .top-line1 {
     margin-right: -30px;
     margin-bottom: 30px;
     margin-top: -10px;

   }

   .top-line {

     margin-right: -50px;
     margin-right: -20px;
     font-size: 2rem;
     line-height: 20px;
     font-weight: 900;

     text-transform: uppercase;
     margin-bottom: 40px;
   }

   .bnj {
     margin-top: 12vh;
   }

   .bnj a {
     text-align: center;
   }

   .dsis {

     display: none;
   }

   .home__hero-img {

     margin-top: -30px;

   }

   .bnj {
     display: grid
   }

   .disand {
     display: none;
   }

   .col1 {
     max-width: 100%;
     flex-basis: 100%;
     margin-bottom: 10vh;
   }

   .col {
     max-width: 100%;
     flex-basis: 100%;

   }
 }