/* @import url('https://fonts.googleapis.com/css2?family=Vazirmatn:wght@200&display=swap');

body {
  font-family: 'Vazirmatn', sans-serif;
} */
@font-face {
  font-family: "Rabar_022";
  src: local("Rabar_022"),
    url("./fonts/Rabar/Rabar_022.ttf") format("truetype");
}

.color {
  color: #03071eb7;
}