.bod {
  position: absolute;
  left: 50%;
  display: grid;
  transform: translate(-50%);
  width: 100%;
}

.table {
  font-size: 1.77em;
  table-layout: fixed;
  text-align: center;
  position: absolute;
  top: 80px;
  width: 100%;

  z-index: -333;
}

.trname {
  text-align: right;
  padding-right: 15px;
}

.nya {
  margin-top: 200px;
}

tr.th {
  white-space: nowrap;
}

.t1 {
  padding: 0.5em 3vw;
}

.t2 {
  padding: 0.5em 3vw;
}

.t3 {
  padding: 0.5em 3vw;
}

.t4 {
  padding: 0.5em 20vw;
  width: 100px;
}

td {
  padding: 0.1em 0.3vw;
  border: none;
}

tr:nth-child(odd) td {
  background-color: #303853;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  font-size: 1em;
  position: absolute;
  left: 50%;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.205);
  transform: translate(-50%);
}

.pagination>.active>a {
  border-bottom: 6px solid #f00946;
  color: #fafafa;
}

.pagination>li>a {
  border: 1px solid #00000000;

  padding: 5px 8px;
  outline: none;
  cursor: pointer;
}
.peshawa{
  display: none;
}
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  color: white;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #ffffff;
}

@media screen and (max-width: 400px) {
  .back {
    margin-top: 160%;
    position: absolute;
  }

  .table tr {
    margin-bottom: 1px;
    margin-top : 50px;
    height: 180px;
  }
  .peshawa{
    display: flex;
  }
}

@media (max-width: 900px) {
  .table thead {
    display: none;
  }



  .bod h1 {
    font-size: 1.2em;
  }

  .nya {
    margin-top: 500px;
  }

  .pagination {
    position: absolute;
    margin: 0px auto;
    top: 105%;
    left: 50%;

    transform: translate(-50%);
    font-size: 1em;
  }

  .loader {
    position: absolute;
    left: 50%;
    top: 40%;
    display: grid;
    transform: translate(-50%);
  }

  .table {
    margin-top: 6vh;
    background-color: rgba(202, 27, 27, 0);
    font-size: 1.2em;

  }

  .table,
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }

  .table tr {
    margin-bottom: 1px;
    border-radius: 2px;
    height: 175px;
  }

  .table .trname {
    padding-right: 20%;
  }

  .table .trdis {
    padding-right: 18%;
  }

  .table .trnum {
    padding-right: 45%;
  }

  .table .trsas {
    padding-right: 28%;
  }

  .pagination>li>a {

    padding: 5px 5px;

  }

  .table td {

    text-align: right;
    white-space: normal;
    padding-right: 50%;
    padding-top: 10px;

    position: relative;
  }

  .table td::after {
    white-space: nowrap;
    content: attr(data-label);
    position: absolute;
    top: 10px;
    right: 0;
    font-size: 0.9em;

    left: 49%;
    width: 40%;
    padding-left: 15px;

    text-align: right;
  }
}